<mat-sidenav-container class="sidenav-container" >
  <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="'dialog'" [mode]="'over'">
    <mat-toolbar>Menu</mat-toolbar>
    @defer (on immediate) {
      <mat-nav-list>
        @for (navItem of sideNavItems; track navItem) {
          <a mat-list-item [routerLink]='navItem.route' (click)="drawer.close()">{{navItem.name}}</a>
        }
      </mat-nav-list>
    }
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      @defer (on immediate) {
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.open()">
            <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
      }
      <span style='display:contents'>
        <img src='assets/logo.svg' alt='App Speed Logo' height='150%' width='auto'/>
      </span>
    </mat-toolbar>
    <ng-content/>
  </mat-sidenav-content>
</mat-sidenav-container>
